﻿#import-mapping{
    .item{
        border-radius: 4px;
        background: #f9f9f9;
        border: #e5e5e5 1px solid;
        padding: 5px;
    

        &.item-for-mapping {    
            background: #b5de96;
        }   

        &.item-mapped {
            background: #f9f9f9;
        }

        &.item-from-app{
            background: #f9f9f9;
        }
    }

    .fields-group{
        margin: 0 0 10px 0;
        position: initial;
    }
}

#import-unique-fields{
    table{
        background: white;
        width: 50%;

        td.td-is-unique{
            width: 20px;
        }
    }
}