@import-once "../Variables.less";

#userinfo_view {

    .control-span {
        .text-overflow();
    }

    label {
        &.user-name, &.user-title {
            .text-overflow();
        }

        &.user-name {
            font-size: @baseFontSize * 1.2;
            font-weight: bold;
            margin-top: 2px;
            margin-bottom: 0px;
            line-height: @baseFontSize * 1.2;
        }

        &.user-title {
            font-size: @baseFontSize * 0.8;
            margin-bottom: 4px;
        }
    }
}

.ImageField-control
{
	position: relative;
	min-height: 50px;

	.ImageField-container
	{
		position: relative;
		margin-right: 1%;
		float: left;

		img
		{
			.border-radius(3px);
		}

		#ImageField-deleter
		{
			position: absolute;
			right: 0;
			top: 0;
			width: 13px;
			height: 15px;
			background: white;
			.border-radius(0 0 0 3px);
		}
	}

	#UserInfoPart_Image-changer
	{
		overflow: hidden;
		position: relative;
		margin-bottom: 2em;

		input
		{
			padding-left: 15px;
			position: absolute;
			top: -15px;
			left: -15px;
			.opacity(0);
		}
	}
}

.content-item.user
{
	> .row-fluid
	{
		margin-bottom: 9px;
	}

	img.ImageField
	{
		.border-radius(3px);
	}
}

.user-image
{
	border: 1px solid @inputBorder;

	background-position: center;
	background-size: cover;
    margin-right: 10px;

	&.size16x16
	{
		.border-radius(2px);
	}

	&.size32x32
	{
		.border-radius(3px);
	}

	&.size64x64
	{
		.border-radius(5px);
	}
}

.selected-user-autocomplete {
    margin-right: 2px;
}
