﻿@import-once "../Variables.less";
@import-once "../bootstrap/less/mixins.less";

#application-store-sub-menu-widget {
    li.organization-filter {
        a {
            line-height: 26px;

            &.selected {
                background-color: @bodyPanelBackgroundColor;
                border-top: 1px #ddd solid;
                border-bottom: 1px #ddd solid;
            }
        }
    }
}
#choosed-applications-container {
    label {
        margin-bottom: 0;
    }

    form {
        margin: 0;
    }

    .row-fluid {
        margin-bottom:5px;
    }
}

.application-pack-badge
{
    i
    {
        display: inline-block;
        width: 26px;
        height: 26px;
        background-image: url('../Content/glyphicons-fixed-small.png');
    }
}

#application-store-list, .application-store, .application-pack
{
    .application-pack-badge, .application-store-badge
    {
        .badge-header
        {
            h4
            {
                margin: 0;
            }
        }

        .badge-content
        {
            white-space: normal;
        }
    }
}

.application-store-badge
{
    .badge-content
    {
        height: @baseLineHeight * 3;
    }
}

.application-pack-badge {
    .badge-content {
        height: @baseLineHeight * 5;

        .abstract-text {
            overflow: hidden;
            height: @baseLineHeight * 3;
        }

        .images {
            #gradient > .horizontal(darken(@wellBackground, 3%), #ffffff);
            max-height: 26px;
            overflow: hidden;
        }
    }
}

.appstore-description
{
    height: 90px;
}

.application-pack, .application-store {
	.created-by-widget {
		.well {
			.box-sizing(border-box);
			.text-overflow();

			.company-name {
				.text-overflow();
				margin-top: 0px;
			}

			.created-by-details {
				&.has-profile-logo {
					margin-left: 80px;
				}

				.user-details {
					.text-overflow();
				}

				.view-company-profile {
					margin-top: 6px;
				}
			}

			.profile-logo {
				float: left;
				margin-top: 0px;
			}

			display: inline-block;
			overflow: hidden;
			max-width: 100%;
		}
	}
}
#share-buttons-container {
    margin-left: 0;

    li {
        display: inline;

        a img {
            width: 16px;
            height: 16px;
        }
    }
}