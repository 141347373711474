﻿@import-once
"../Variables.less";
@import-once "../Mixins.less";

@overduecolor: red;
@todaycolor: green;
@latercolor: gray;


a.label.label-text {
    .text-overflow();

    position:relative;

    i.icon-remove {
        position: absolute;
        right: 6px;
        top: 4px;
    }
}

.labels-sidebar-container {
    overflow: visible;
    margin-bottom: 5px;

    .label-item {
        background-color: white;
        padding: 5px;
        border-radius: 7px;
        margin: 0px -10px;
        padding: 6px 10px;


        .delete-label {
            display: none;
        }

        &:hover {
            background-color: #f8f8f8;
            border-color: #d0d0d0;
            overflow: visible;

            .delete-label {
                display: block;
                margin-top: 4px;
                opacity: 0.2;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .label-caption {
            line-height: 22px; //equals to label image
            .text-overflow();
        }
    }
}

.new-label-color
{
    margin: 3px 5px 0px 0px;
}

.task-modal {
	overflow: visible;
}

.kobix-tasks, #tasks
{
	/*#region Tasks list*/
	    
	.tasks-list .task-item {
        .well();
        .well-small();
	    
        background-color: @controlBlockBackgroundColor;

		.task-header {
			display: table;
			table-layout: fixed;

			margin: -9px;
			padding: 9px;

			width: 100%;

			vertical-align: top;
								
			input[type=checkbox] {
				margin: auto;
			}

			[class^="icon-"], [class*=" icon-"] {
				margin-top: 0;
			}
				
            &:hover
            {
				// todo Intorduce variables for colors below
                background-color: #f8f8f8;
                border-color: #d0d0d0;
            }
		}
			
		.task-header > * {
			display: table-cell;

			line-height: @baseLineHeight;
				
			vertical-align: top !important;
				
			* {
				vertical-align: middle !important;
			}
		}
		.task-header > .task-date {
			width: 150px;
		}
		.task-header > .task-name {
			.value {
				display: inline-block;
				max-width: 100%;

				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.task-header > .task-actions {
			width: @iconSize * 4 + 4;

			text-align: right;

			[class^="icon-"], [class*=" icon-"] {
				text-align: left;
			}
		}

		.task-header + hr {
			margin: 9px -9px;
		}

		.task-detail {
			.task-info {
				word-wrap: break-word;
			}
		}
	}

	.label {
		color: black;
		font-weight: normal;
		
		text-shadow: none;

		&:hover {
			color: white;
		}
	}

	/*#endregion*/
}

div.popover-body {
    box-sizing: border-box;
    min-width: 100px;
    min-height: 50px;
    display: none;
    position: absolute;
    margin: 0px;
    padding: 8px 0px 0px 0px;
    z-index: @zindexDropdown + @zindexModal + 1;
    font-size: 13px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    border-top: none;
}

.task {

    .task-information textarea {
        display: block;
        resize: none;
    }

    .task-reference {
        position: relative;

        .task-privacy-setting {
            line-height: @inputHeight;
            position: absolute;
            top: 0px;
            right: 6px;
        }
    }

    .due {
        margin-bottom: 9px;
    }

    .popover-body {
        .dropdown {
            display: inline-block;
        }

        .remind {
            margin-bottom: 12px;
        }

        .input-prepend.one {
            white-space: nowrap;
        }
    }

    input.datepicker-hidden {
        // ↓ see #ui-datepicker-div.tasks-datepicker-duedate
        bottom: 1px;
        left: 1px;
        position: absolute;
        .square(1px);
        border: 0;
        opacity: 0;
    }
}

#ui-datepicker-div.tasks-datepicker-duedate {
	// ↑ see input.datepicker-hidden
	margin-left: -1px;
	margin-top: 3px;
}

#task-sidebar-item {
	.tasks-list {
		margin: 6px 0;
		.task {
			border: 1px solid #CCC;
			border-left: 5px solid #e5e5e5;
            border-top: none;
            border-bottom: none;
			border-radius: 3px;
			padding: 4px;
            margin: 5px 0;
			&.overdue {
				border-left-color: #FDD;
			}
			&.today {
				border-left-color: #b4de9e;
			}
			.task-body {
				
			}
		}
	}
}
