﻿@import "Applications.ImportExport.less";

@import "ApplicationsMenuBar.less";

@import "ApplicationItem.Detail.less";

@import "ApplicationItem.Edit.less";

@import "ApplicationItems.Badge.less";

@import "ApplicationItems.Table.less";

@import "ApplicationBuilder.less";

.application-filters
{
    [class^="span"]
    {
        &.size16x16
        {
            .square(16px);
        }

        min-height: 0;
    }
}

//#application-sidebar {
//    position: absolute;
//}

//#application-item {
    //position: relative;
//}

.application-detail
{
    margin-top: 25px;

    .user-icon-16x16
    {
        width: 16px;
        height: 16px;
    }

    .form-horizontal .controls
    {
        padding-top: 5px;
        margin-left: 140px;
    }

    .form-horizontal .control-label
    {
        width: 120px;
    }
}

.application-reference-detail
{
    padding: 8px;
    width: auto;

    .app-ref-icon
    {
        display: inline-block;
        width: 41px;
        height: 41px;
        border-radius: 5px;
        background-image: url(../Content/glyphicons-fixed.png);
        vertical-align: middle;
        margin: 1px;
        border: solid 1px #e4e4e4;
        background-color: white;
    }

    .span9
    {
        padding-left: 7px;
    }

    .application-item-name
    {
        font: normal bold 12px arial, sans-serif;
        margin-right: 5px;
    }

    .dropdown
    {
        margin: 8px auto;
    }
}

.contact-header
{
	border-radius: 5px 5px 0 0;
	margin-bottom: 0px;

}

.contact-item {

	.contact-body {
		border-width: 0px;
		margin-bottom: 0;
		min-height: 0;
			
		div.contact-field {
			margin: 8px 8px;
			position: relative;
			.text-overflow();

			& > div {
				word-wrap: break-word;
			}
		}
	}

	padding: 8px 8px;

	& > div.contact-header {
		zbackground-color: #F6F6F6;
		zborder: 2px solid #AAA;
		zborder-radius: 5px;
		zmargin: 4px 0px;
		zmin-width: 400px;
		padding: 10px;
		border-width: 0px;

		& > div {
			height: 64px;
			zmargin: 8px 8px;
			position: relative;
		}

			& > div.contact-image {
				cursor: pointer;
				width: 64px;
				float: left;
				margin-left: 10px;
				margin-right: 10px;

				& > img {
					background-position: center;
					border-radius: 5px;
					background-size: cover;
					height: 64px;
					width: 64px;
				}
			}

			& > div.contact-content {
				margin-left: 76px;
				margin-right: 72px;

				& > .contact-name {
					font: normal bold 14px arial, sans-serif;
					cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
				}

				& > .contact-title {
					font: 11px arial, sans-serif;
					line-height: 14px;
				}

				& > .contact-email {
					font: 11px arial, sans-serif;
					line-height: 26px;
				}

				a:link, a:hover, a:visited, a:active {
					text-decoration: none;
				}
			}

			& > div.contact-action {
				float: right;
				width: 80px;
				padding: auto;

				> div.dropdown {
					margin: 18px auto;

					> a.dropdown-toggle {
						width: 100%;
						.box-sizing(border-box);
					}
				}
			}
	}

	& > div.contact-body {
		//background-color: #F6F6F6;
		//border: 2px solid #AAA;
		//border-radius: 10px;
		//margin: 4px 0px;
		//min-width: 400px;
		//padding: 0px 2px;
		//display: none;
	}
}

.comments-container {
    margin-left: @activityOffset;
    margin-top: 10px;

    .activity {
        .action-comment {
            margin-left: 7px;
            display: none;
        }

        &.comment-item {
            &:hover {
                .action-comment {
                    display: block;
                    opacity: 0.2;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        min-height: 45px;

        .comment-text {
            margin-left: 50px;
        }
    }
}


.enumeration-field-small.value {
    padding: 3px;
    border-radius: 5px;
}


.image-field
{

	.thumbnails
	{

		.thumbnail
		{

			&.image
			{
				width: 100px;
				height: 85px;
				margin: 2px;
				display: inline-block;
				vertical-align: middle;
                background-color: white;

				&.failed
				{
					background-color: rgba(255, 0, 0, 0.1);
				}

					&.failed:hover
					{
						background-color: rgba(255, 0, 0, 0.2);
					}

				&.loaded
				{
					background-color: rgba(0, 255, 0, 0.1);
				}

					&.loaded:hover
					{
						background-color: rgba(0, 255, 0, 0.2);
					}

				.footer-controls
				{
					height: 8px;
					margin-top: 3px;

					.progress
					{
						width: auto;
						height: 8px;
						margin: 0;
						margin-right: 10px;
						line-height: 10px;
					}

					.close
					{
						width: 8px;
						line-height: 8px;
						font-size: 12px;
						background-color: transparent;
					}
				}
			}

				&.image.new
				{

					.new-image
					{
						position: relative;
						width: 100px;
						height: inherit;
						background-color: #DDD;
						background-image: url(../Content/png/glyphicons_190_circle_plus.png);
						background-position: center center;
						background-repeat: no-repeat;

						input
						{
							position: absolute;
							width: inherit;
							height: inherit;
							opacity: 0;
						}
					}

					&:hover .new-image
					{
						background-color: #CCC;
					}
				}

			.preview
			{
				width: 100px;
				height: 75px;

				img
				{
					width: inherit;
					height: inherit;
				}
			}
		}

			.thumbnail:hover
			{
				border-color: #BBB;
				-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
				-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
			}
	}
}

.views {
    width: 265px; 
    white-space: normal;
    .view{
        padding-left: 15px;
    }
}

#application-toolbar
{

    .popover-body
    {
        font-size: 13px;
    }

    .sorting, .display-type, .table-options
    {

        li .icon-check
        {
            visibility: hidden;
        }

        li.selected .icon-check
        {
            visibility: visible;
        }
    }

    .filter-group
    {
        border-radius: 7px;
        background-color: @white;
    }

    .filter
    {
    }
}

.application-developer-table {
    width: 100%;

    thead {
        font-weight: bold;
    }

    tbody {
        .even { background-color: #FCFCFC; }
        form { display: inline; }
        td { padding: 0 5px; }
    }

    .column-40 {
        width: 40%;
    }

    .column-20 {
        width: 20%;
    }

    .input-cell {
        input {
            width: 100%;
            margin-top: 9px;
        }
    }
}

.importer-header {
    overflow: hidden;
    padding-bottom: 30px;

    .g33 {
        float: left;
        width: 35%;
    }

    .g66 {
        float: left;
        width: 65%;
    }

    h3 {
        margin-bottom: 5px;
    }

    .subheading {
        margin-bottom: 15px;
    }

    .step-indicator {
        width: 100%;
        position: relative;

        .line {
            height: 1px;
            background: #ccc;
            width: 25%;
            position: absolute;
            top: 6px;
            left: 20%;
            z-index: 1;
        }

            .line.line-2 {
                left: 53%;
            }

        .steps {
            z-index: 2;
            position: relative;

            .step {
                width: 33%;
                float: left;
                text-align: center;
                -ms-word-break: break-all;
                -ms-word-wrap: break-word;
                word-wrap: break-word;
                overflow-wrap: break-word;
                word-break: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;

                .bullet {
                    height: 10px;
                    width: 10px;
                    -moz-border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -ms-border-radius: 10px;
                    border-radius: 10px;
                    display: inline-block;
                    background: #ccc;
                }

                .step-label {
                    padding: 0 5px;
                    font-weight: bold;
                    color: #9A9898;
                }
            }

                .step.active {
                    .bullet {
                        background: #5092bd;
                    }

                    .step-label {
                        color: inherit;
                    }
                }
        }
    }
}
    
.importer-divider {
    border-bottom: 1px solid #dddddd;
    margin: 20px 0;
}

.importer-upload-illustration {
    display: block;
    width: 685px;
    height: 386px;
    margin: 0 auto;
    background: url("../../Content/images/upload-illustration.png") no-repeat 0 70px;

    .header-excel {
        width: 300px;
        float: left;
        padding-left: 66px;
        font-size: 1.2rem;
        padding-top: 30px;

        .icon-32-excel {
            background: url("../../Content/images/excel-icon-32.png") no-repeat 0 0;
        }
    }

    .header-kobix {
        width: 185px;
        padding-left: 130px;
        float: left;
        font-size: 1.2rem;
        padding-top: 30px;

        .icon-32-kobix {
            background: url("../../Content/images/logo-icon-32.png") no-repeat 0 0;
        }
    }

    .icon-32 {
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }
}

#import-duplication-handling-window {
    h1 {
        font-size: 11pt;
        font-weight: bold;
        padding-left: 180px;
        margin-bottom: 0.6em;
        line-height: none;
    }

    .import-mapping-window-illustration {
        background: url("../../Content/images/key-excel-white.png") no-repeat;
        height: 111px;
        width: 158px;
        float: left;
        margin-right: 22px;
    }

    .import-mapping-window-content {
        margin-left: 180px;

        span.icon-16-key-button {
            background: url("../../Content/images/key-button.png") no-repeat;
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            height: 18px;
            margin-bottom: 1px;
        }
    }

    .import-mapping-window-buttons {
        overflow: hidden;
        padding-top: 20px;
        clear: left;

        .btn {
            float: right;
        }
    }
}

#import-mapping-preview-window {
    .modal-body {
        .preview-loader {
            margin: 0 auto;
            width: 32px;
            height: 32px;
        }
    }

    .border-right {
        border-right: 1px solid #dddddd;
    }

    .preview-unique-field:first-child {
        border-top: none;
        margin-top: -20px;
    }

    .preview-unique-field, .preview-child-field {
        padding: 10px 20px;
        overflow: hidden;
        border-top: 1px solid #dddddd;

        .preview-unique-field-title, .preview-unique-field-value {
            width: 35%;
            float: left;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
            padding: 5px 10px 5px 28px;
            -ms-word-break: break-all;
            -ms-word-wrap: break-word;
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }

        .preview-unique-field-value {
            width: 65%;
            padding: 5px 15px 5px 0;

            .pull-right {
                height: 28px;
            }

            .btn-key {
                cursor: pointer;
                -moz-border-radius: 5px;
                -webkit-border-radius: 5px;
                -ms-border-radius: 5px;
                border-radius: 5px;
                display: inline-block;
                width: 16px;
                height: 16px;
                line-height: 16px;
                padding: 5px;
                border: 1px solid #ddd;
                background-repeat: no-repeat;
                background-color: #f6f6f6;

                span {
                    background: url("../../Content/images/btn-key.png") no-repeat;
                    display: block;
                    width: 16px;
                    height: 16px;
                }
            }

                .btn-key:hover {
                    background-color: #ddd;
                    text-shadow: 1px 1px 1px #fff;
                }

                .btn-key.pressed-button {
                    background: -webkit-linear-gradient(top, #444, #999);
                    background: -o-linear-gradient(top, #444, #999);
                    background: -ms-linear-gradient(top, #444, #999);
                    background: -moz-linear-gradient(top, #444, #999);
                    border: 1px solid #999;
                    border-top: 1px solid #444;
                    background-color: #444;

                    span {
                        background: url("../../Content/images/btn-key-pressed.png") no-repeat;
                    }
                }
        }

        .secondary-text {
            color: #979797;
        }
    }

    .preview-child-field {
        border-top: none;
    }
}

#importer-unique-description-container {
    margin-top: -20px;

    #importer-unique-description {
        position: fixed;
        display: none;
        padding: 10px;
        background: #fffee0;
        border: 1px solid #dddddd;
        border-left: none;
        font-size: 10pt;
    }
}

.align-right {text-align:right !important;}
.text-bold {font-weight:bold;}
.color-gray {color:#ccc;}

/* #region Application item preview */
.application-item-preview {
    display: none;
    position: fixed;
    
    right: 0;
    top: 41px;
    bottom: 0;

    z-index: 1040;
    
    width: 500px;
    padding: 10px 40px 10px 10px;

    background-color: white;

    overflow-y: auto;

    .control-label {
        width: 120px;
    }

    &.form-horizontal .controls {
        margin-left: 140px;
    }

    // todo
    a[target="_blank"]{
        display: none;
    }
}
/* #endregion */