//
// Modals
// --------------------------------------------------

// Background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @zindexModalBackdrop;
    background-color: @black;
    // Fade for backdrop
    &.fade {
        opacity: 0;
    }
}

.modal-backdrop,
.modal-backdrop.fade.in {
    .opacity(40);
}

// Base modal
.modal-relative {
    width: 824px;
}

.modal {
    border-radius: 3px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    /*transform: translate(-50%,-50%);*/
    transform: translateX(-50%) translateY(calc(-50% - .5px));
    z-index: @zindexModal;
    width: unset;
    height: unset;
    /*border: 1px solid rgba(0,0,0,.3);*/
    *border: 1px solid #999; /* IE6-7 */
    .border-radius(3px);
    .box-shadow(0 3px 7px rgba(0,0,0,0.3));
    .background-clip(padding-box);
    // Remove focus outline from opened modal
    outline: none;

    &.fade {
        .transition(e('opacity .3s linear, top .3s ease-out'));
        top: -25%;
    }

    &.fade.in {
        top: 50%;
    }
}

@media (max-width: 1090px) {
    .modal {
        /*margin: -250px 0 0 -410px;*/
    }
}

.modal-header {
    padding: 24px 26px 16px 24px;
    border-bottom: 1px solid #eee;
    letter-spacing: 0.62px;
    font-size: 16px !important;
    overflow: hidden;
    font-family: Roboto;
    font-weight: 600;
    // Close icon
    .close {
        margin-top: 2px;
        float: right;
        text-transform: uppercase;
    }
    // Heading
    h3 {
        margin: 0;
        float: left;
    }
}

// Body (where all modal content resides)
.modal-body {
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
    min-width:320px;
}
// Remove bottom margin if need be
.modal-form {
    margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
    padding: 16px 24px;
    overflow: hidden;
    margin-bottom: 0;
    text-align: right; // right align buttons
    border-top: 1px solid #ddd;
    .border-radius(0 0 6px 6px);
    .box-shadow(inset 0 1px 0 @white);
    .clearfix(); // clear it in case folks use .pull-* classes on buttons
    // Properly space out buttons
    .btn + .btn {
        margin-left: 5px;
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}

.notification-modal {
    .modal-header {
        padding: 16px 0px;

        a.close {
            display: none;
        }

        h3 {
            text-align: center;
            width: 100%;
            margin: 0px !important;
        }
    }

    .modal-body {
        text-align: center;
        padding: 16px 0px;
    }

    .modal-footer {
        display: none;
    }
}
