﻿@import-once "../Variables.less";
@import-once "../Mixins.less";

body { 
//	padding-top: 50px; 
	background-color: @bodyBackground;
    background-image: url("../Images/bg-wood.jpg");
//    background-position-y: 53px;
    background-attachment: fixed;
}

#footerLoadingAnimation {
    position: fixed;
    z-index: 100000;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 22px;

    div.content {
        width: 304px;
        height: 22px;
        background-color: white;
        border: 1px solid;
        border-bottom: none;
        margin: auto;
        font-weight: bold;
        .box-shadow(0 2px 6px hsla(0, 0%, 0%, 0.30));

        span {
            vertical-align: middle;
        }
    }
}

#loadingAnimation {
    z-index: 1000000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    width: 100%;
    vertical-align: middle;
    min-height: 100%;

    .content {
        margin-top: 15%;
    }
}

#layout-wrapper {
    @mainColor: #efefef;
    @paragraphColor: #878787;
    @padding: 10px;

	.container-fluid.container-indented {
		padding-left: @baseFontSize * 1.5;
		padding-right: @baseFontSize * 1.5;
	}

	.header-wrapper {
        top: 0;
        background-image: url("../Images/bg-wood.jpg");
        position: fixed;
        width: 100%;
        z-index: 1030;

		.header-content {
            background-attachment: fixed;
            margin-top: @toolbarHeight + @toolbarContentOffset;
        }
    }

	.paper-top {
        position: relative;
		margin: -10px 0;
        @top: 9px;
        background-image: url("../Images/bg-paper-top.png");
        height: @paperTopHeight;
    }

	#layout-box {
        padding-top: 15px;
        background-color: @bodyPanelBackgroundColor;
        @param: -18px 0 20px -20px #000, 18px 0 20px -20px #000, 0 18px 20px -20px #000;
        .box-shadow(@param);
        //margin-top: @baseLineHeight;
        margin-bottom: @baseLineHeight;
        
		.body-panel {
            padding: 15px;
        }

		#layout-content-wrapper {
            padding-top: @toolbarHeight + @toolbarContentOffset + @paperTopHeight + @headerWidgetHeight;
            > .span3.navigation-container {
                width: 21%;
                border-left: 1px solid @hrBorder;
                
            }

			> .span9.main-container {
                width: 77.9%;

                .body-panel {
                    padding: 15px;
                }
            }

			.span4.sidebar-container {
                padding: 0 15px;
            }

			> .span8.content-container {
                width: 63.9%;
            }
        }

        &.withoutSearchBox {
            margin-top: @toolbarHeight + @paperTopHeight;
        }

		#layout-footer-wrapper {
			.credits {
                border-top: 1px solid @hrBorder;
                text-align: center;
                margin-top: 15px;
                padding: 12px;
            }
        }
    }
}