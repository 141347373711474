﻿@import "Variables.less";
@import "bootstrap/less/mixins.less";
/*@import "FontAwesome/less/font-awesome.less";*/

.ui-front {
	z-index: 100;
}

/*#region Button */

.btn {
	.border-radius(@borderRadiusLarge);
}

/* clickable icon/mini-button */
.icon-x {
	vertical-align: initial !important;
	text-align: center;
	font-style: normal;
	cursor: pointer;
}

/*#endregion */

/*#region Input */

// Everything else
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	&:focus {
		border-color: @inputBorderActive;
		@inputActiveShadow: hsla(hue(@inputBorderActive), saturation(@inputBorderActive), lightness(@inputBorderActive), 0.6);
		.box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{inputActiveShadow}");
	}
}

.form-search.medium-margins,
.form-inline.medium-margins,
.form-horizontal.medium-margins {
  > input,
  textarea,
  select,
  .help-inline,
  .uneditable-input,
  .input-prepend,
  .input-append,
  .btn-group,
  button {
    margin-right: 4px;
  }
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
	margin-top: 2px;
}

input[type=text] {
	.box-sizing(border-box);
	height: @inputHeight !important;
}

/*#endregion */

/*#region Links */

/* Dotted link, used to show user that something will happen (popover/dialog/picked) when he click on it */
a.etc {
	border-bottom: 1px dotted;

	&:hover {
		text-decoration: none;
	}
}

/*#endregion */

/*#region Forms */

.form-horizontal {
	.control-group {
		> .control-label {
			font-weight: bold;
		}
	}

	.control-label {
		word-wrap: break-word;
	}

	.help-block {
		margin-top: 2px;
		margin-bottom: 0;
		font-size: 11px;
		color: #888;
	}

	.btn-group {
		+ .help-block {
			margin-top: @baseLineHeight / 2;
		}
	}
}

/*#endregion */

/*#region Navs */

.nav.nav-list {
	> li a {
		color: @textColor;
		border-bottom: 1px solid transparent;
		border-top: 1px solid transparent;

		&:hover {
			border-bottom: 1px solid @btnBackgroundHighlight;
			border-top: 1px solid @btnBackgroundHighlight;
			background-color: transparent;
		}
	}

	> .active {
		> a, > a:hover {
			color: @white;
			background-color: @ui-clickable-active-bg;
			border-bottom: @ui-clickable-active-border;
			border-top: @ui-clickable-active-border;
		}
	}
}

/*#endregion */

/*#region Sprites */

.icon-due:extend(.icon-calendar all) {
}

.icon-responsible:extend(.icon-heart all) {
}

.icon-creator:extend(.icon-user all) {
}

.icon-organization:extend(.icon-play all) {
}

.icon-workspace:extend(.icon-play-circle all) {
}

.icon-application:extend(.icon-file all) {
}

.icon-label:extend(.icon-tag all) {
}

/*#endregion */

/*#region Scaffolding */

.container-fluid {
	max-width: 1440px;
	min-width: 960px;
	margin: 0 auto;
}

a:hover {
	text-decoration: none;
}

/*#endregion */

/*#region Table */

table {
	max-width: none;
}

.table-bordered {
	@tableRadius: 0px;

	.border-radius(@tableRadius);
	// For first th or td in the first row in the first thead or tbody
	thead:first-child tr:first-child th:first-child,
	tbody:first-child tr:first-child td:first-child {
		-webkit-border-top-left-radius: @tableRadius;
		border-top-left-radius: @tableRadius;
		-moz-border-radius-topleft: @tableRadius;
	}

	thead:first-child tr:first-child th:last-child,
	tbody:first-child tr:first-child td:last-child {
		-webkit-border-top-right-radius: @tableRadius;
		border-top-right-radius: @tableRadius;
		-moz-border-radius-topright: @tableRadius;
	}
	// For first th or td in the first row in the first thead or tbody
	thead:last-child tr:last-child th:first-child,
	tbody:last-child tr:last-child td:first-child,
	tfoot:last-child tr:last-child td:first-child {
		.border-radius(0 0 0 @tableRadius);
		-webkit-border-bottom-left-radius: @tableRadius;
		border-bottom-left-radius: @tableRadius;
		-moz-border-radius-bottomleft: @tableRadius;
	}

	thead:last-child tr:last-child th:last-child,
	tbody:last-child tr:last-child td:last-child,
	tfoot:last-child tr:last-child td:last-child {
		-webkit-border-bottom-right-radius: @tableRadius;
		border-bottom-right-radius: @tableRadius;
		-moz-border-radius-bottomright: @tableRadius;
	}
	// Special fixes to round the left border on the first td/th
	caption + thead tr:first-child th:first-child,
	caption + tbody tr:first-child td:first-child,
	colgroup + thead tr:first-child th:first-child,
	colgroup + tbody tr:first-child td:first-child {
		-webkit-border-top-left-radius: @tableRadius;
		border-top-left-radius: @tableRadius;
		-moz-border-radius-topleft: @tableRadius;
	}

	caption + thead tr:first-child th:last-child,
	caption + tbody tr:first-child td:last-child,
	colgroup + thead tr:first-child th:last-child,
	colgroup + tbody tr:first-child td:last-child {
		-webkit-border-top-right-radius: @tableRadius;
		border-top-right-radius: @tableRadius;
		-moz-border-radius-topright: @tableRadius;
	}
}

.table-striped {
	tbody {
		tr:nth-child(odd) td,
		tr:nth-child(odd) th {
			background-color: transparent;
		}

		tr:nth-child(even) td,
		tr:nth-child(even) th {
			background-color: @tableBackgroundAccent;
		}
	}
}

.table-hover {
	tbody {
		tr:hover td,
		tr:hover th {
			background-color: @tableBackgroundHover;
		}
	}
}

a.dropdown-toggle{
    cursor:pointer;
}
/*#endregion */
