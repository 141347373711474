﻿@import "../Styles/Bootstrap/less/variables.less";
@import "../Styles/Bootstrap/less/mixins.less";
@import "../Styles/Bootstrap/less/forms.less";

.kobix-validation-error {
  // Style inputs accordingly
  .checkbox&,
  .radio&,
  input&,
  select&,
  textarea& {
    color: @errorText;
  }
  input&,
  select&,
  textarea& {
    border-color: @errorText;
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken(@errorText, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@errorText, 20%);
      .box-shadow(@shadow);
    }
  }
  // Give a small background color for input-prepend/-append
  .input-prepend .add-on,
  .input-append .add-on {
    color: @errorText;
    background-color: @errorBackground;
    border-color: @errorText;
  }

  button, .btn {
	  border-color: @errorText;
  }
}