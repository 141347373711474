﻿/*#region Inline editing */

.application-detail {
    .widget-control.shape {
        position: relative;
        clear: initial;

        .manage-actions {
            position: absolute;

            right: 0;
        }
    }
}

/*#endregion*/