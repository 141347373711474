//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindexPopover;
  display: none;
  width: 236px;
  padding: 1px;
  background-color: @popoverBackground;
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  .border-radius(6px);
  .box-shadow(0 5px 10px rgba(0,0,0,.2));

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -10px; }
  &.right   { margin-left: 10px; }
  &.bottom  { margin-top: 10px; }
  &.left    { margin-left: -10px; }

}

.popover-title {
  margin: 0; // reset heading margin
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: @popoverTitleBackground;
  border-bottom: 1px solid darken(@popoverTitleBackground, 5%);
  .border-radius(5px 5px 0 0);
}

.popover-content {
  padding: 9px 14px;
  p, ul, ol {
    margin-bottom: 0;
  }
}

// Arrows
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow:after {
  content: "";
  z-index: -1;
}

.popover {
  &.top .arrow {
    bottom: -@popoverArrowWidth;
    left: 50%;
    margin-left: -@popoverArrowWidth;
    border-width: @popoverArrowWidth @popoverArrowWidth 0;
    border-top-color: @popoverArrowColor;
    &:after {
      border-width: @popoverArrowOuterWidth @popoverArrowOuterWidth 0;
      border-top-color: @popoverArrowOuterColor;
      bottom: -1px;
      left: -@popoverArrowOuterWidth;
    }
  }
  &.right .arrow {
    top: 50%;
    left: -@popoverArrowWidth;
    margin-top: -@popoverArrowWidth;
    border-width: @popoverArrowWidth @popoverArrowWidth @popoverArrowWidth 0;
    border-right-color: @popoverArrowColor;
    &:after {
      border-width: @popoverArrowOuterWidth @popoverArrowOuterWidth @popoverArrowOuterWidth 0;
      border-right-color: @popoverArrowOuterColor;
      bottom: -@popoverArrowOuterWidth;
      left: -1px;
    }
  }
  &.bottom .arrow {
    top: -@popoverArrowWidth;
    left: 50%;
    margin-left: -@popoverArrowWidth;
    border-width: 0 @popoverArrowWidth @popoverArrowWidth;
    border-bottom-color: @popoverArrowColor;
    &:after {
      border-width: 0 @popoverArrowOuterWidth @popoverArrowOuterWidth;
      border-bottom-color: @popoverArrowOuterColor;
      top: -1px;
      left: -@popoverArrowOuterWidth;
    }
  }
  &.left .arrow {
    top: 50%;
    right: -@popoverArrowWidth;
    margin-top: -@popoverArrowWidth;
    border-width: @popoverArrowWidth 0 @popoverArrowWidth @popoverArrowWidth;
    border-left-color: @popoverArrowColor;
    &:after {
      border-width: @popoverArrowOuterWidth 0 @popoverArrowOuterWidth @popoverArrowOuterWidth;
      border-left-color: @popoverArrowOuterColor;
      bottom: -@popoverArrowOuterWidth;
      right: -1px;
    }
  }
}
