﻿@import-once "../Variables.less";
@import-once "../Mixins.less";

#layout-wrapper
{
    #layout-header
    {
		@logoWidth: 45px;
        @logoHeight: 45px;
        @headerHeight: 70px;
        .box-shadow(0 1px 12px rgba(0,0,0,0.2));
        background-color: @bodyPanelBackgroundColor;
        border-bottom: 1px solid @hrBorder;
		padding: 0 @baseFontSize;
        
        .header-form
        {
            height: @headerHeight;

            .application-logo
            {
                margin-right: 12px;
                margin-top: (@headerHeight - @logoHeight) / 2;

                .application-logo-image
                {
                    background-size: contain;
                    background-image: url("../images/logo-icon-large.png");
                    width: @logoWidth;
                    height: @logoHeight;
                }
            }

            .header-control-group
            {
                display: inline-block;
                padding-top: (@headerHeight - @inputHeight) / 2;
                vertical-align: middle;
            }

            .user-info
            {
                margin-top: -3px;

                .dropdown ul
                {
                    left: auto;
                    right: 0;
                }

                a.user-image-wrapper
                {
                    color: @textColor;
                }

                #user-menu
                {
                    display: inline-block;

                    .user-image-wrapper
                    {
                        .user-image
                        {
                            border: 1px solid @inputBorder;
                            border-radius: 3px;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 6px;
                        }

                        .caret
                        {
                            border-top-color: @textColor;
                            vertical-align: middle;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
