﻿@import "Variables.less";
@import "Mixins.less";

ul[data-role="autocomplete"] {
    margin: 0;
    overflow: hidden;
    padding: 0px 2px;

    &.input-block {
        margin-bottom: @baseFontSize * 0.75;
    }

    & > li {
        display: inline-block;
        list-style-type: none;
        margin: 2px 0px;
        padding: 2px 4px;
        white-space: nowrap;
        height: 18px;
        line-height: 18px;
        vertical-align: middle;
        border: 1px solid transparent;

        &.bit-icon {
        }


        &.bit-selected {
            .ui-widget-content();
            .ui-corner-all();
            width: auto;
            cursor: default;

            & > i {
                line-height: 18px;
                vertical-align: text-top;
                cursor: pointer;
            }

            span {
                width: auto;
            }
        }

        &.bit-input {
            min-width: 120px;
            padding-left: 0;

            input {
                margin: 0;
                height: 100%;
                width: 100%;
                overflow: visible;
                border: 0;
                outline: 0;
                padding-left: 0;
                padding-right: 0;
                .box-shadow(none);
            }
        }

        & > [class^="icon-"], [class*=" icon-"] {
            line-height: 18px;
            margin-left: 2px;
            vertical-align: text-bottom !important;
        }
    }
}

.ui-autocomplete {
    .border-top-radius(0);
    border-top: none;
    overflow: hidden;
    padding: 0;
    position: absolute;

    li {
        @borderColor: @tableBorder;

        background: @ui-clickable-default-bg;
        font-weight: normal;

        &.ui-autocomplete-category {
            .muted();
            background-color: @btnBackground;
            border-top: 1px solid @borderColor;
            padding: @baseFontSize / 2;
        }

        &.ui-menu-item {
            cursor: pointer;
            border-top: 1px solid @borderColor;

            a {
                .border-radius(0);

                &.ui-state-hover {
                    border-color: transparent;
                    border-top-color: @borderColor;
                }
            }

            .ui-autocomplete-item, .ui-autocomplete-description {
                /*display: block;*/
            }

            .ui-autocomplete-item {
                margin-left: 12px;
            }

            .ui-autocomplete-description {
                .muted();
                margin-left: 12px;
                font-size: @baseFontSize * 0.8;
            }
        }

        .image-autocomplete {
            height: 18px;
            width: 18px;
            border-radius: 3px;
            margin-right: 5px;
        }
    }
}
