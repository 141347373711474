﻿.application-builder
{
    .accordion-group
    {
        background-color: @white;
    }
}

#application-name {
	.icon-name-value {
		display: inline-block;
		width: 41px;
		height: 41px;
		border-radius: 5px;
		background-image: url(../Content/glyphicons-fixed.png);
		vertical-align: middle;
		margin: 1px;
	}
}
#application-builder {
	.item {
		border-radius: 4px;
		margin: 10px 0;
		background: @brightGrayColor;
		border: #e5e5e5 1px solid;
	}

	.handler
	{
		cursor: move;
		padding: 4px 8px;
	
		&:hover i.icon-remove {
			.opacity(20);
		}
		i.icon-remove
		{
			margin: 2px;
			cursor: pointer;
			.opacity(30);
			&:hover {.opacity(100);}
		}
	}

	& input, & select, & textarea {
		padding: 2px 4px;
		box-shadow: none;
		line-height: 1.1em;
		border-radius: 3px;
		height: auto;
	}

	textarea {
		display: block;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		width: 90%;
	}

	.enum
	{
		border-radius: 3px;
		margin: 4px 0;
		padding: 3px 5px;
		background: white;
		position: relative;
		border: #E5E5E5 1px solid;
	}

	#application-builder-fields {
		margin-bottom: 1em;
		
		i {
			vertical-align: middle;
		}

		.fields { 
			min-height: 200px;
			padding: 0 10px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			background-color: darken(@brightGrayColor, 2%);

			.field {
				position: relative;

				input { margin-bottom: 0; }
				.icon-remove { position: absolute; right: 8px; top: 6px;}
				.settings {
					padding: 10px 5px;
					position: relative;
					height: auto;
					border-top: solid 1px white;
					> div 
					{
						width: 100%;
						position: relative;
						clear: both;
						padding-bottom: 7px;
					
						&:before, &:after {display: table;content: "";  /* fix clearing */ } 
						&:after {clear: both;  /* fix clearing */ } 

						label {
							display: inline;
							font-weight: bold;
							text-align: right;
							font-size: smaller;
							color: Gray;
							position: relative;
							top: 2px;
						}
						> label
						{
							display: block;
							top: 3px;
							width: 25%;
							float: left;
							padding-right: 10px;
							+ div
							{
								width: 74%;
								margin-left: 26%;
							}
						}
					}
					.help-block
					{
						margin: 0;
						padding: 0 3px;
						font-size: smaller;
						color: #7C7C7C;
						line-height: 1.1em;
					}
					.enum {
						.icon-remove { position: absolute; right: 4px;top: 3px;}
						span {
							display: block;
							padding-right: 20px;
							padding-left: 40px;
							position: relative;
							height: 20px;
						}
						b {
							position: absolute;
							display: block;
							width: 2px;
							left: 8px;
							top: 5px;
							height: 15px;
							border: solid #bbb 1px;
							border-width: 0 1px;
						}
						input
						{
							width: 100%;
							border: none;
							outline: none;
							padding: 0;
							margin: 0;
							line-height: 12px;
							&:hover { background-color: #FFFFEF; }
							&:focus { background-color: #FFFDC7; }
						}
						.color-picker
						{
							position: absolute;
							top: -1px;
							left: 14px;
							display: inline-block;
                            width: 17px;
                            border-radius: 3px;
                            margin-top: 2px;
                            height: 17px;
						}
					} //.enum
					.calculation-field {
						.span4 { min-height: 0; }
						label { display: block; text-align: center; font-weight: bold; margin: 0;}
						.operator { position: relative; text-align: center; }
						.operand { position: relative; text-align: center; }

						.operand .dropdown-toggle, .operator .dropdown-toggle { cursor: pointer; }

						.operands, .operators { position: absolute; left: 25%; top: 16px; min-width: 100%; max-width: 200px }
						.canceler { position: absolute; right: 4px; top: 4px; cursor: pointer; }
						.dropdown-menu { text-align: left; }
                        .dropdown-menu li a{ white-space:nowrap; overflow:hidden; text-overflow:ellipsis; }
						.btn-group { display: inline-block; }
					}
				} //.settings
			} // .field
		} // .fields

		.ui-sortable-placeholder {
			background: #e4e4e4 !important;
			visibility: visible !important;
			border-radius: 4px;
			border: grey dashed 1px !important;
			cursor: pointer;
		}
		.ui-sortable-placeholder * { visibility: hidden; }
		
	} // #application

	&.collapse.in {
		overflow: visible;
	}
} // #application-builder

#application-interaction {
	.vote-labels {
		input, label {
			display: inline;
			margin-bottom: 0;
		}
	}
}


.application-metadata.icons {
	margin: 0 auto;
    width: 520px;
	i {
		display: inline-block;
		width: 41px;
		height: 41px;
		border-radius: 5px;
		background-image: url(../Content/glyphicons-fixed.png);
    
		margin: 1px;
	}
	i.selected {
		background-color: orange;
		border: 1px solid #666;
		margin: 0;
	}
	i:hover {
		background-color: #ddd;
	}

}

#application-tasks {
    .task-name {
        width: 100%;
    }
}
