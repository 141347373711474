﻿@import "FontAwesome/less/variables.less";
@import "FontAwesome/less/icons.less";
@import "Variables.less";
@import "Mixins.less";

.kobix-widget-picker {
	.widget-list {
		display: table;
		width: 100%;

		border-spacing: 5px;
		margin: -5px;

		& > .widget-list-row {
			display: table-row;
			
			& > .widget-cell {
				display: table-cell;
				width: 100% / 3;
			}
		}
	}

	.widget {
		&:extend(.well);
		&:extend(.well-small);

		margin-bottom: 0;
		cursor: pointer;

		.widget-name {
			margin-bottom: 4px;
		}

		.widget-desc {

		}

		&:hover {
			background-color: @panelBackgroundColor;
		}
	}
}
#application-sidebar .kobix-widgets {
    margin: 0px 24px;
}
.kobix-widgets {
    .widget {
        &:extend(.well);
        &:extend(.well-small);
        background-color: @panelBackgroundColor;
        cursor: pointer;

        &:hover {
            border: 1px solid #d3d3d3;
            .box-shadow(inset 0px 0px 3px rgba(0, 0, 0, 0.15));
        }

        .widget-header {
            margin-bottom: 9px;

            .widget-name {
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: bold;
            }

            [class^="icon-"], [class*=" icon-"] {
                .square(14px);
                font-size: 14px;
                cursor: pointer;
            }
        }

        .widget-content {
            // Text widget style
            .text-content {
                word-wrap: break-word;
            }

            .link-content {
                ul, ol {
                    margin: 0 0 0 20px;
                    word-wrap: break-word;
                }
            }
            // Image widget style
            img {
                width: 100%;
            }
            // Report widget style
            .table {
                margin-bottom: 0;
            }

            .single {
                font-size: 50px;
                font-weight: bold;
                line-height: 100px;
                text-align: center;
            }
        }

        .widget-header, &.ui-sortable-helper {
            cursor: move;
        }
    }

    .widget.edit {
        .btn-toolbar {
            margin-top: 0;
        }

        .widget-action-button {
            min-width: 84px;
        }
    }
}

// Toolbar for selecting aggregation function, operands and operator
#report-widget-function {
	margin-bottom: 8px;

	// bootstrap fix: Incorrect displaying append and prepend controls
	.input-append, .input-prepend {
		margin-bottom: 0;
	}

	// Report widget validation style
	.iw-validation-error + .btn-group > .btn {
		border-left-color: @errorText;
	}
}

@media print {
    .actions {
        display: none;
    }
}

.calendar-widget-item
{
    margin: 9px 0;
}

.employeenetwork-widget
{
    background-color: @white;
}

.employeenetwork-members-header-image
{
	width: 32px;
	float: left;
	padding: 2px;
	margin-right: 10px;
	border: 1px solid @ui-content-border;
	border-radius: 3px;
}

.employeenetwork-members-header-title
{
	margin-left: 40px;
}

#whoishere
{
    #whoishere-header
    {
        .link-title
        {
            color: inherit;
        }
    }

    .body
    {
        background-color: @white;
        border: @ui-content-border;
        border-bottom: none;
        border-radius: 3px 3px 0 0;
        padding: 10px;
    }

    .foot
    {
        border: @ui-content-border;
        border-radius: 0 0 3px 3px;
        background-color: @formActionsBackground;
        padding: 10px;
    }

    .title
    {
    }
}

.sidebar-right {
	.widget-item.badge-widget {
		margin: @baseLineHeight * 1.2 0;
		border: none;
		padding: 10px;

		.badge-header h4 {
			margin: 0;
		}
	}
}

.add-widget-button {
    border: 1px dashed;
    padding: 5px;
    margin-top: 5px;
    border-radius: 10px;
}

/*#region Frequently used apps */

.frequently-used-apps-widget {
	.frequently-used-apps-item {
		line-height: @inputHeight;
		padding: 2px 6px;
		margin-bottom: 4px;

		[class^="icon-app-"], [class*=" icon-app-"] {
			display: inline-block;
			width: 26px;
			height: 26px;
			background-image: url('../Content/glyphicons-fixed-small.png');
			vertical-align: middle;
		}

		.actions a, a.app-name {
			&:hover {
				text-decoration: underline;
			}
		}

		.actions a {
			.muted();
		}

		a.app-name {
			color: @textColor;
			font-weight: bold;
		}
	}
}

/*#endregion */

/*#region Search */

.widget-search-form {
	&.widget-sub-navigation {
		float: none;
        padding: 0 15px;

		form.search-form {
			margin-bottom: 10px;
		}
	}
}

/*#endregion */

#organization-sub-menu-widget {
    padding: 15px;
    background-color: @ui-content-bg;
}