﻿/* Markdown editor */
li.wmd-spacer {
	display: none;
}

.wmd-prompt-background
{
	background-color: Black;
}

.wmd-prompt-dialog
{
	border: 1px solid #999999;
	background-color: #F5F5F5;
}

.wmd-prompt-dialog > div {
	font-size: 0.8em;
	font-family: arial, helvetica, sans-serif;
}


.wmd-prompt-dialog > form > input[type="text"] {
	border: 1px solid #999999;
	color: black;
}

.wmd-prompt-dialog > form > input[type="button"]{
	border: 1px solid #888888;
	font-family: trebuchet MS, helvetica, sans-serif;
	font-size: 0.8em;
	font-weight: bold;
}
/* EOF Markdown editor */


button {
  .btn;
}

.ui-autocomplete-loading { background: white url('images/ui-anim_basic_16x16.gif') right center no-repeat; }
