@import-once "bootstrap/less/bootstrap.less";

.fileuploader
{
	.new-file
	{
		position: relative;
		overflow: hidden;
		.user-select(none);

		input[type="file"]
		{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0;
		}
	}
}

	.fileuploader.file-attachment.lines
	{
		.files
		{
			display: block;
			padding: 8px 0;
			//
			font-size: 10px;
			/*font-weight: bold;
			text-transform: uppercase;*/

			.file
			{
				//height: 18px;
				margin: 2px 0 4px;
				//padding-right: 24px;
				border-left-width: 8px;
				//
				min-height: 1px;
				cursor: pointer;
				.user-select(none);

				.content-zone
				{
					position: relative;
					padding: 0 8px;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;

					.progress
					{
						position: absolute;
						margin: 0px;
						height: 2px;
						//
						left: 0px;
						bottom: 0;
						right: 22px;
					}

					[class^="icon-"], [class*=" icon-"]
					{
						margin-top: 2px;
					}
				}

				&.added
				{
					border-left-style: solid;
					border-left-color: #46a546;

					&:hover .content-zone
					{
						background-color: lighten(#46a546, 51%);
					}
				}

				&.failed
				{
					border-left-style: solid;
					border-left-color: #f37264;
					color: red;

					&:hover .content-zone
					{
						background-color: lighten(#f37264, 30%);
					}
				}

				&.sent
				{
				}

				&.deleted
				{
					border-left-style: solid;
					border-left-color: black;
				}

				&.saved
				{
					border-left-style: solid;
					border-left-color: #0064cd;

					&:hover .content-zone
					{
						background-color: lighten(#0064cd, 58%);
					}
				}
			}
		}
	}
