﻿#kobix {
    @shadow: 0 1px 2px rgba(0,0,0,0.3);

    .shadow() {
        .box-shadow(@shadow);
    }
}

.navbar-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: @zindexFixedNavbar;
}

.navbar-inner {
    background: none;
    .box-shadow(none);
    text-shadow: none;

    .zone-navigation {
        //#gradient > .vertical(@navbarBackgroundHighlight, @navbarBackground);
        .clearfix;
        @shadow: 0 1px 2px rgba(0,0,0,0.3);
        .box-shadow(@shadow);
        .border-radius(3px);
    }
}

/*
TODO: make hack of collapse.js
No needed due to `twitter bootstrap 2.0.4`
*/
//Collapse of menu in left sidebar is broken in Mozila with it, but there's broken workspace menu showing without it
.subnav .collapse.in.out {
    overflow: hidden;
}

.subnav .collapse.in {
    overflow: visible;
}

.subnav {
    section.organization {
        margin-bottom: 5px;
    }

    hr {
        margin: 14px 0;
        border-top-color: @hrBorder;
    }

    .nav-list {
        padding: 0;
    }

    .organization .body {
        vertical-align: middle;
        cursor: pointer;
        position: relative;
        display: block;

        & > .image, img {
            border: 1px solid @inputBorder;
            margin-right: 10px;
            padding: 2px;
            border-radius: 3px;
            display: inline-block;
            vertical-align: middle;
        }

        & > span {
            vertical-align: middle;
        }
    }

    .workspace > a {
        display: block;
        padding: 5px;
        padding-left: 20px;
        color: @textColor;

        &:hover {
            text-decoration: none;
            background: #F3F3F3;
        }
    }

    .organization > a {
        display: block;
        padding: 5px;
        color: @textColor;

        &:hover {
            text-decoration: none;
            background: #F3F3F3;
        }
    }

    .new.organization > a, .new.workspace > a {
        background-color: @createButtonColor;
    }

    .organization, .workspace {
        position: relative;

        .dropdown {
            position: absolute;
            right: 2px;
            width: 22px;
            height: 18px;
            top: 11px;
        }

        .dropdown-toggle {
            display: none;
            padding: 2px;
            .border-radius(3px);
            width: 14px;
            height: 14px;
        }

        &:hover > .dropdown > .dropdown-toggle {
            display: block;
            .opacity(20);
        }

        & > .dropdown.open .dropdown-toggle {
            display: block;
            .opacity(100);
        }

        & > .dropdown .dropdown-toggle:hover {
            display: block;
            .opacity(100);

            i {
                background-color: none;
            }
        }
    }

    .organization .dropdown {
        top: 11px;
    }

    .workspace .dropdown {
        top: 4px;
    }
}

.nav-list.nav-list-hierarchical {
    li + .nav-header {
        margin-top: 0;
    }
}

.navigator-screen {
    display:none
}


