﻿@applicationTypeIconSize: 52px;

#applications-menubar
{
    .application-menu-bar-one-line
    {
        height: @applicationTypeIconSize;
        overflow: hidden;
    }

    p
    {
        height: 10px;
    }

    .application-menu-bar-resizable
    {
        max-width: 83.157894737%;
        float: left;
    }


    .application-menu-bar-icon-arrow-right
    {
        background-repeat: no-repeat;
        background-position: 50%;
        background-image: url(../../Content/png/glyphicons_right_arrow.png);
        width: 41px;
        height: 41px;
    }
    
    .application-menu-bar-icon-arrow-down
    {
        background-repeat: no-repeat;
        background-position: 50%;
        background-image: url(../../Content/png/glyphicons_down_arrow.png);
        width: 41px;
        height: 41px;
    }

    a
    {
        color: @gray;
        line-height: 0.9em;
        font-size: 10px;

        i
        {
            border-radius: 5px;
        }

            i.selected
            {
                border: 1px solid #666666;
                background-color: orange;
                width: 39px;
                height: 39px;
            }

        &:hover i
        {
            background-color: @grayLight;
        }
    }

        a.active
        {
            font-weight: bold;
            ztext-decoration: underline;
            color: black;
        }

    .application
    {
        color: gery;
        float: left;
        width: 70px;
        text-align: center;
        height: @applicationTypeIconSize;

        img
        {
            vertical-align: middle;
        }

        i
        {
            display: inline-block;
            .square(41px);
            margin: 0 10px;
            background-image: url(../Content/glyphicons-fixed.png);
        }

        &.btn-add i
        {
            background-position: 187px -1823px;
        }
    }

        .application.activity
        {
            i
            {
                background-image: url(/Themes/Kobikit.Theme/Content/png/glyphicons_282_cardio.png);
                background-position: 8px 8px;
                background-repeat: no-repeat;
            }
        }
}
