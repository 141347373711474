﻿#globalSearchContainer
{
    .table{
        background: white;

        .td.user-image{
            width: 42px;
        }
    }
}

ul.nav.globalSearch{
    .quick-search{
        margin:11px 0 0 0;

        li.divider{
            width: 100%;
        }
    }

    button{
        vertical-align: top;
    }

    .popover-body{
        color: black;
    }
}