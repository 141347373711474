﻿.oauth-container div {
    margin: 3px 0;
}
.oauth-container div button {
    width: 200px;
    height: 30px;
    text-align: left;
}
.oauth-container div button img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin: 0 8px;
}