@import-once "../bootstrap/less/bootstrap.less";

.contact-phone
{
    padding-right: 10px;
}
.popover-body.popover-contact-actions {
    min-width: 150px;
}
.modal.window-contact-editing {
	width: 600px;

	.modal-body {
		max-height: none;
	}
}

.indicator {
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 8px;
    height: 7px;
    background: url("../images/sprite-presence.png") 0 0 no-repeat transparent;
}
.indicator.online {

} 

.indicator.offline {
    background-position:0 -100px;
}

.indicator.idle {
    background-position:0 -7px;
}

.modal.window-join-members {
    margin: -350px 0 0 -400px;
    width: 800px;

    .modal-body {
        max-height: none;

        .tabbable {
            height: 300px;

            .nav.nav-tabs {
                margin-left: 4px;
                width: 154px;
            }

            .tab-content {
                margin-right: 160px;
                margin-bottom: 0;
                height: 280px;

                .tab-pane {

                    &.initial-state {
                        position: absolute;
                        width: 588px;
                        height: 278px;
                        .border-radius(5px);
                        border: 1px solid #D6D3CA;
                        background: #FFFEE0;

                        .arrow-up {
                            position: absolute;
                            width: 61px;
                            height: 18px;
                            top: 15px;
                            right: -24px;
                            background-image: url("../images/green-sketch-arrow-1.png");
                        }

                        .text-up {
                            position: absolute;
                            top: 25px;
                            right: 35px;
                            color: #93CF66;
                        }

                        .arrow-down {
                            position: absolute;
                            width: 21px;
                            height: 75px;
                            bottom: -50px;
                            right: 75px;
                            background-image: url("../images/green-sketch-arrow-2.png");
                        }

                        .text-down {
                            position: absolute;
                            bottom: 20px;
                            right: 100px;
                            text-align: right;
                            color: #93CF66;
                        }

                        .text-message {
                            margin-top: 110px;
                            margin-left: 125px;
                        }
                    }
                }
            }

            .well {
                background-color: #FAFAFA;
            }

            .contact-list-items {
                overflow: auto;
                height: 210px;

                .contact-list-item {
                    .contact-name {
                        .text-overflow();
                        width: 90%;
                    }

                    &.active {
                        background-color: #E9F5E2;
                    }

                    .box-sizing(border-box);
                    .user-select(none);
                    width: 33.33%;
                    height: 50px;
                    border-left: 1px solid #D4D4D4;
                    border-bottom: 1px solid #D4D4D4;
                    background-color: #E8E8E8;
                    text-shadow: 0 1px 0 white;
                    color: #999;
                    padding: 7px;
                    float: left;
                    cursor: pointer;
                    overflow: hidden;

                    & > .user-image.size32x32 {
                        margin-top: 2px;
                    }

                    & > .user-info {
                        position: relative;
                        margin-left: 40px;

                        & > .check-control {
                            position: absolute;
                            right: 0px;
                        }
                    }
                }
            }
        }
    }
}
