#calendar {
    .calendar-item {
        overflow: hidden;
        clear: left;
        position: relative;
        min-height: 33px;
        padding: 20px 0;
        border-top: 1px solid #E4E4E4;
        width: 100%;

        &.odd {
            background-color: #F6F6F6;
        }

        &.today {
            background-color: #FFFEE0;

            .day-badge-month {
                background: #9DD869;
                border: 1px solid #9DD869;
            }
        }

        .day-badge {
            width: 30px;
            margin: 0 20px;
            float: left;
        }

        .day-new-task {
            width: 14px;
            margin: 5px 20px;
            float: right;
            
            opacity: 0;

            cursor: pointer;
        }

        &:hover .day-new-task {
            opacity: 1;
        }

        & > .items-group {
            margin-left: 70px;
            margin-right: 40px;
            overflow: hidden;

            & > .item-info {
                & > .time {
                    width: 80px;
                    margin: 0 20px;
                    float: left;
                }

                & > .name {
                    margin-left: 120px;
                    overflow: hidden;

                    & > .type {
                        color: #999;
                    }

                    .url {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
