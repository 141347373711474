﻿/* Common styles for form of Application */

.application-reference-img-autocomplete {
    display: inline-block;
    width: 16.4px;
    height: 16.4px;
    margin-right: 2px;
    margin-top: 1px;
    background-image: url('../Content/glyphicons-fixed-tiny.png');
}

div.popover-body.edit-application {
    padding: 0px;
    width: 300px;



    .modify-icon {
        &.container {
            padding: 15px;
        }

        .icon {
            width: 70px;
            height: 70px;
            background-image: url("../images/modify-app.png");
        }
    }

    .modify-text {
        line-height: 1;
        margin-bottom: 3px;
        margin-right: 3px;
    }

    .title {
        background-color: #f0f0f0;
        border: 1px solid #e5e5e5;
        padding-left: 10px;
    }

    .item {
        padding-left: 10px;
        border: 1px solid #e5e5e5;

        a {
            color: #525252;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

.application-item-edit {
    .field-error {
        background-color: @errorBackground;
    }

    .control-group {
        position: relative;

        .ui-widget-header {
            background-color: @sliderValueColor;
        }

        .control-hint {
            position: absolute;
            display: none;
            width: 150px;
            height: auto;
            right: -185px;
            top: 5px;
            z-index: 1000;
            padding: 10px;
            background: #fffee0;
            color: #666;
            border: 1px solid #E2E3C8;
            .border-radius(3px);
            .box-shadow(0 3px 5px rgba(0,0,0,0.15));
            font-size: 12px;
            line-height: 16px;

            .control-hint-inner {
            }

            .arrow {
                position: absolute;
                width: 0;
                height: 0;
                top: 10px;
                left: -6px;
                border-right: 5px solid #E2E3C8;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;

                .arrow-border {
                    position: absolute;
                    top: -5px;
                    left: 1px;
                    border-right: 5px solid #fffee0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                }
            }
        }
        // .control-hint
        &:hover .control-hint {
            display: block;
        }

        .enumeration-field {
            .colored {
                input {
                    float: left;
                    line-height: 2em;
                    position: relative;
                    top: 3px;
                }

                    input:not(:checked) + label {
                        background: none !important;
                    }

                label {
                    margin-left: 10px;
                    margin-bottom: 4px;
                    padding: 2px 8px;
                    border-radius: 4px;
                    line-height: 1.7em;
                }
            }

                .colored.inlined {
                    input {
                        display: none;
                    }

                        input:not(:checked) + label {
                            border-color: transparent;
                        }

                    label {
                        display: inline-block;
                        border: 1px solid #ddd;
                        margin-right: 20px;
                        margin-left: 0;

                        &:hover {
                            cursor: pointer;
                            border: 1px solid #bbb;
                        }
                    }
                }
        }
        // .enumeration-field
        .slider-field {
            padding-top: 8px;

            .value {
                position: relative;
                top: -3px;
                ztext-align: center;
            }
        }

        .appreference-field {
            span.label {
                margin-right: 10px;
            }

                span.label:last-child {
                    margin-right: 0;
                }
        }
    }
    // .control-group
}
//.application-item-edit
