﻿#applications {
    table.applications-items-table {
        table-layout: fixed;
        tbody {
            tr {
                height: 1em;

                td {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    table.table-items {
        .item-checked {
            background-color: #e9f5e2 !important;

            td:first-child, th:first-child {
                color: #0066cc !important;
            }

            td {
                background-color: #e9f5e2 !important;
            }
        }

        tr:hover {
            td {
                &:hover {
                    span.number{
                        display:none;
                    }
                    .icon-square-o {
                        display: inline-block;
                    }
                }
            }
        }

        td {
            i.icon-square-o {
                display: none;
            }
        }

        tr {
            th:first-child {
                width: 15px;
            }
        }
    }
}
