@import-once "../Variables.less";
@import-once "../Mixins.less";

@activityOffset: 50px;

#activity-stream {
    .stream {
        margin-bottom: @baseFontSize * 1.5;
        min-height: @baseLineHeight * 4;

        .application-image {
            width: 76px;
            height: 76px;
            background-repeat: no-repeat;
        }



        &:hover .header .actions i {
            .opacity(15);
        }

        .header .actions {
            width: 20%;
            float: right;
            text-align: right;

            i {
                .opacity(0);

                &:hover {
                    .opacity(100);
                }
            }
        }

        .img {
            float: left;
            margin-right: 10px;

            img {
                width: 40px;
                height: 40px;
                float: left;
                .border-radius(3px);
            }
        }

        .activities, .more {
            margin-left: @activityOffset;
            overflow: hidden;
        }

        .body {
            min-height: 2*@baseLineHeight;
        }
    }
}