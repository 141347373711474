﻿#web-form {
    .ui-accordion .ui-accordion-header .ui-icon {
        left: auto;
        right: .5em;
    }

    .control-label {
        font-weight: bold;
    }

    .button-save {
        margin: 7px 0;
    }

    .sidebar {
        .add-domain-input {
            width: 100%;
        }

        .textarea-src {
            cursor: default;
        }

        .text-overflow {
            .text-overflow();
        }
    }
}

#save-and-share-webform-window {
    input {
        width: 100%
    }
            .textarea-src {
            cursor: default;
            width: 80%;
            }

}

#index-page-window {
    height: 80vh !important;
    min-height: 80vh !important;
}


