﻿/* Name: Specific/Organizations/Module.less */

#workspace-edit
{
    div.controls{
        margin-left: initial;
    }

    label.control-label{
        width: initial;
        margin-right: 10px
    }
}

#user-management{
    .contact-content{
        height: auto;
    }
}