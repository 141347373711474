/* =========================================================
 * bootstrap-lightbox.less
 * ========================================================= */
 
.lightbox {
	background-color: transparent;
	text-align: center;
	line-height: 0;
	z-index: 1050;
	position: relative;
	top: 70px;
	outline: none;

	.hide {
		display: none;
	}

	.in {
		display: block;
	}
}
.lightbox-content {
	display: inline-block;
	padding: 10px;
	background-color: #ffffff;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.3);
	*border: 1px solid #999;
	/* IE6-7 */
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
	background-clip: padding-box;
	
	.lightbox-caption {
		padding: 2%;
		position: absolute;
		left: 11px;
		right: 12px;
		bottom: 11px;
		background: #000;
		background: rgba(0, 0, 0, 0.6);
		color: white;
		text-align: center;
		text-shadow: 0 -1px 0 #000;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
		font-size: 14px;
		line-height: 18px;
	}
}
.lightbox-header {
	.close {
		color: white;
		margin-right: -16px;
		margin-top: -16px;
		font-size: 2em;
		opacity: .8;
		filter: alpha(opacity=80);

		:hover {
			opacity: .4;
			filter: alpha(opacity=40);
		}
	}
}