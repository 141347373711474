﻿/* Component containers
----------------------------------*/
.ui-widget {
    font-family: @ui-font-family;
    font-weight: @ui-font-weight;
    font-size: @ui-font-size;
}

.ui-widget .ui-widget {
    font-size:  @ui-sub-font-size;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: @ui-sub-font-family;
    font-weight: @ui-sub-font-weight;
    font-size:  @ui-sub-font-size;
}


.ui-widget-content {
    border: @ui-content-border;
    background: @ui-content-bg;
    color: @ui-content-text;
}

.ui-widget-content a {
    color: @ui-content-text;
}

.ui-widget-header {
    border: @ui-header-border;
    background:@ui-header-bg;
    color: @ui-header-text;
    font-weight: @ui-header-font-weight;
}

.ui-widget-header a {
    color: @ui-header-text
}

/* Interaction states
----------------------------------*/
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: @ui-clickable-default-border;
    background: @ui-clickable-default-bg;
    font-weight: @ui-clickable-default-font-weight;
    color: @ui-clickable-default-text;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
    color: @ui-clickable-default-text;
    text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    border: @ui-clickable-hover-border;
    background: @ui-clickable-hover-bg;
    font-weight: @ui-clickable-hover-font-weight;
    color: @ui-clickable-hover-text;
}

.ui-state-hover a, .ui-state-hover a:hover {
    color: @ui-clickable-hover-text;
    text-decoration: none;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    border: @ui-clickable-active-border;
    background: @ui-clickable-active-bg;
    font-weight: @ui-clickable-active-font-weight;
    color: @ui-clickable-active-text;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
    color: @ui-clickable-active-text;
    text-decoration: none;
}

.ui-widget :active {
    outline: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: @ui-highlight-bg;
    background: @ui-highlight-border;
    color: @ui-highlight-text;
    font-weight: @ui-highlight-font-weight;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a,.ui-widget-header .ui-state-highlight a {
    color: @ui-highlight-text;
    font-weight: @ui-highlight-font-weight;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
    border: @ui-error-border;
    background: @ui-error-bg;
    color: @ui-error-text;
    font-weight: @ui-error-font-weight;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
    color: @ui-error-text;
    font-weight: @ui-error-font-weight;
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
    color: @ui-error-text;
    font-weight: @ui-error-font-weight;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
    font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary,  .ui-widget-header .ui-priority-secondary {
    opacity: .7;
    filter: Alpha(Opacity=70);
    font-weight: normal;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none;
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    width: 16px;
    height: 16px;
    background-image: url(@ui-content-icon-image);
}

.ui-widget-content .ui-icon {
    background-image: url(@ui-content-icon-image);
}

.ui-widget-header .ui-icon {
    background-image: url(@ui-header-icon-image);
}

.ui-state-default .ui-icon {
    background-image: url(@ui-clickable-default-icon-image);
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
    background-image: url(@ui-clickable-hover-icon-image);
}

.ui-state-active .ui-icon {
    background-image: url(@ui-clickable-active-icon-image);
}

.ui-state-highlight .ui-icon {
    background-image: url(@ui-highlight-icon-image);
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
    background-image: url(@ui-error-icon-image);
}

/* positioning */
.ui-icon-carat-1-n {
    background-position: 0 0;
}

.ui-icon-carat-1-ne {
    background-position: -16px 0;
}

.ui-icon-carat-1-e {
    background-position: -32px 0;
}

.ui-icon-carat-1-se {
    background-position: -48px 0;
}

.ui-icon-carat-1-s {
    background-position: -64px 0;
}

.ui-icon-carat-1-sw {
    background-position: -80px 0;
}

.ui-icon-carat-1-w {
    background-position: -96px 0;
}

.ui-icon-carat-1-nw {
    background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
    background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
    background-position: -144px 0;
}

.ui-icon-triangle-1-n {
    background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
    background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
    background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
    background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
    background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
    background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
    background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
    background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
    background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
    background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
    background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
    background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
    background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
    background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
    background-position: -64px -32px;
}

.ui-icon-arrow-1-sw {
    background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
    background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
    background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
    background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
    background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
    background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
    background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
    background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
    background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
    background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
    background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
    background-position: 0 -48px;
}

.ui-icon-arrowthick-1-ne {
    background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
    background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
    background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
    background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
    background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
    background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
    background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
    background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
    background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
    background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
    background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
    background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
    background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
    background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
    background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
    background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
    background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
    background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
    background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
    background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
    background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
    background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
    background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
    background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
    background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
    background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
    background-position: -176px -64px;
}

.ui-icon-arrow-4 {
    background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
    background-position: -16px -80px;
}

.ui-icon-extlink {
    background-position: -32px -80px;
}

.ui-icon-newwin {
    background-position: -48px -80px;
}

.ui-icon-refresh {
    background-position: -64px -80px;
}

.ui-icon-shuffle {
    background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
    background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
    background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
    background-position: 0 -96px;
}

.ui-icon-folder-open {
    background-position: -16px -96px;
}

.ui-icon-document {
    background-position: -32px -96px;
}

.ui-icon-document-b {
    background-position: -48px -96px;
}

.ui-icon-note {
    background-position: -64px -96px;
}

.ui-icon-mail-closed {
    background-position: -80px -96px;
}

.ui-icon-mail-open {
    background-position: -96px -96px;
}

.ui-icon-suitcase {
    background-position: -112px -96px;
}

.ui-icon-comment {
    background-position: -128px -96px;
}

.ui-icon-person {
    background-position: -144px -96px;
}

.ui-icon-print {
    background-position: -160px -96px;
}

.ui-icon-trash {
    background-position: -176px -96px;
}

.ui-icon-locked {
    background-position: -192px -96px;
}

.ui-icon-unlocked {
    background-position: -208px -96px;
}

.ui-icon-bookmark {
    background-position: -224px -96px;
}

.ui-icon-tag {
    background-position: -240px -96px;
}

.ui-icon-home {
    background-position: 0 -112px;
}

.ui-icon-flag {
    background-position: -16px -112px;
}

.ui-icon-calendar {
    background-position: -32px -112px;
}

.ui-icon-cart {
    background-position: -48px -112px;
}

.ui-icon-pencil {
    background-position: -64px -112px;
}

.ui-icon-clock {
    background-position: -80px -112px;
}

.ui-icon-disk {
    background-position: -96px -112px;
}

.ui-icon-calculator {
    background-position: -112px -112px;
}

.ui-icon-zoomin {
    background-position: -128px -112px;
}

.ui-icon-zoomout {
    background-position: -144px -112px;
}

.ui-icon-search {
    background-position: -160px -112px;
}

.ui-icon-wrench {
    background-position: -176px -112px;
}

.ui-icon-gear {
    background-position: -192px -112px;
}

.ui-icon-heart {
    background-position: -208px -112px;
}

.ui-icon-star {
    background-position: -224px -112px;
}

.ui-icon-link {
    background-position: -240px -112px;
}

.ui-icon-cancel {
    background-position: 0 -128px;
}

.ui-icon-plus {
    background-position: -16px -128px;
}

.ui-icon-plusthick {
    background-position: -32px -128px;
}

.ui-icon-minus {
    background-position: -48px -128px;
}

.ui-icon-minusthick {
    background-position: -64px -128px;
}

.ui-icon-close {
    background-position: -80px -128px;
}

.ui-icon-closethick {
    background-position: -96px -128px;
}

.ui-icon-key {
    background-position: -112px -128px;
}

.ui-icon-lightbulb {
    background-position: -128px -128px;
}

.ui-icon-scissors {
    background-position: -144px -128px;
}

.ui-icon-clipboard {
    background-position: -160px -128px;
}

.ui-icon-copy {
    background-position: -176px -128px;
}

.ui-icon-contact {
    background-position: -192px -128px;
}

.ui-icon-image {
    background-position: -208px -128px;
}

.ui-icon-video {
    background-position: -224px -128px;
}

.ui-icon-script {
    background-position: -240px -128px;
}

.ui-icon-alert {
    background-position: 0 -144px;
}

.ui-icon-info {
    background-position: -16px -144px;
}

.ui-icon-notice {
    background-position: -32px -144px;
}

.ui-icon-help {
    background-position: -48px -144px;
}

.ui-icon-check {
    background-position: -64px -144px;
}

.ui-icon-bullet {
    background-position: -80px -144px;
}

.ui-icon-radio-off {
    background-position: -96px -144px;
}

.ui-icon-radio-on {
    background-position: -112px -144px;
}

.ui-icon-pin-w {
    background-position: -128px -144px;
}

.ui-icon-pin-s {
    background-position: -144px -144px;
}

.ui-icon-play {
    background-position: 0 -160px;
}

.ui-icon-pause {
    background-position: -16px -160px;
}

.ui-icon-seek-next {
    background-position: -32px -160px;
}

.ui-icon-seek-prev {
    background-position: -48px -160px;
}

.ui-icon-seek-end {
    background-position: -64px -160px;
}

.ui-icon-seek-start {
    background-position: -80px -160px;
}
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
    background-position: -80px -160px;
}

.ui-icon-stop {
    background-position: -96px -160px;
}

.ui-icon-eject {
    background-position: -112px -160px;
}

.ui-icon-volume-off {
    background-position: -128px -160px;
}

.ui-icon-volume-on {
    background-position: -144px -160px;
}

.ui-icon-power {
    background-position: 0 -176px;
}

.ui-icon-signal-diag {
    background-position: -16px -176px;
}

.ui-icon-signal {
    background-position: -32px -176px;
}

.ui-icon-battery-0 {
    background-position: -48px -176px;
}

.ui-icon-battery-1 {
    background-position: -64px -176px;
}

.ui-icon-battery-2 {
    background-position: -80px -176px;
}

.ui-icon-battery-3 {
    background-position: -96px -176px;
}

.ui-icon-circle-plus {
    background-position: 0 -192px;
}

.ui-icon-circle-minus {
    background-position: -16px -192px;
}

.ui-icon-circle-close {
    background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
    background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
    background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
    background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
    background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
    background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
    background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
    background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
    background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
    background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
    background-position: -192px -192px;
}

.ui-icon-circle-check {
    background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
    background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
    background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
    background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
    background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
    background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
    background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
    background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
    background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
    background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
    background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
    background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
    background-position: -80px -224px;
}


/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    -moz-border-radius-topleft: @ui-corner-radius;
    -webkit-border-top-left-radius: @ui-corner-radius;
    -khtml-border-top-left-radius: @ui-corner-radius;
    border-top-left-radius: @ui-corner-radius;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    -moz-border-radius-topright: @ui-corner-radius;
    -webkit-border-top-right-radius: @ui-corner-radius;
    -khtml-border-top-right-radius: @ui-corner-radius;
    border-top-right-radius: @ui-corner-radius;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    -moz-border-radius-bottomleft: @ui-corner-radius;
    -webkit-border-bottom-left-radius: @ui-corner-radius;
    -khtml-border-bottom-left-radius: @ui-corner-radius;
    border-bottom-left-radius: @ui-corner-radius;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    -moz-border-radius-bottomright: @ui-corner-radius;
    -webkit-border-bottom-right-radius: @ui-corner-radius;
    -khtml-border-bottom-right-radius: @ui-corner-radius;
    border-bottom-right-radius: @ui-corner-radius;
}

/* Overlays */
.ui-widget-overlay {
    background: @ui-modal-overlay-bg;
    opacity: ".@{ui-modal-overlay-opacity}";
    filter: Alpha(Opacity=@ui-modal-overlay-opacity);
}

.ui-widget-shadow {
    margin: @ui-drop-shadow-top-offset 0 0 @ui-drop-shadow-left-offset;
    padding: @ui-drop-shadow-thinkness;
    background: @ui-drop-shadow-bg;
    opacity: ".@{ui-drop-shadow-opacity}";
    filter: Alpha(Opacity=@ui-drop-shadow-opacity);
    -moz-border-radius: @ui-drop-shadow-corners;
    -khtml-border-radius: @ui-drop-shadow-corners;
    -webkit-border-radius: @ui-drop-shadow-corners;
    border-radius: @ui-drop-shadow-corners;
}