@import-once "Variables.less";
@import-once "Mixins.less";

/*#region Badge */

.small-scroll-box {
    overflow-y: scroll;
    height: 136px;
    border: #e5e5e5 1px solid;
}

.badge-widget {

    .answer {
        .votes-number {
            visibility: hidden;
        }

        &:hover {
            .votes-number {
                visibility: visible;
            }
        }
    }

    .ui-widget-content();
    .ui-corner-all();
    padding: @baseFontSize * 0.75;
    position: relative;

    &.shadow-small {
        .box-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
    }

    &.shadow-large {
        .box-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
    }

    &.clickable {
        &:hover {
            background-color: darken(@ui-content-bg, 3%);
            cursor: pointer;
            .transition(background-color .1s linear);
        }
    }

    .actions {
        float: right;
        text-align: right;
    }

    .badge-header, .badge-content, .badge-footer {
        &.text-overflow {
            .text-overflow();

            h1, h2, h3, h4, h5, h6, p {
                .text-overflow();
            }
        }
    }

    .badge-header {
        padding-bottom: @baseFontSize / 1.5;

        &.divided {
            border-bottom: @ui-header-border;
            margin-bottom: @baseFontSize / 1.5;
        }

        & .icons {

            i {
                .opacity(20);

                &:hover {
                    .opacity(100);
                }
            }
        }
    }

    .badge-content {
        overflow: hidden;
    }

    .badge-footer {
        .muted();
        padding-top: @baseFontSize / 1.5;

        &.divided {
            border-top: @ui-header-border;
            margin-top: @baseFontSize / 1.5;
        }
    }
}

.day-badge {
	background: white;
	width: 30px;
	text-align: center;
	.border-radius(3px);
	top: 20px;
	left: 20px;

	.day-badge-month {
		color: white;
		font-size: 10px;
		line-height: 13px;
		text-transform: uppercase;
		-moz-border-radius-topleft: 3px;
		-webkit-border-top-left-radius: 3px;
		-o-border-top-left-radius: 3px;
		-ms-border-top-left-radius: 3px;
		-khtml-border-top-left-radius: 3px;
		border-top-left-radius: 3px;
		-moz-border-radius-topright: 3px;
		-webkit-border-top-right-radius: 3px;
		-o-border-top-right-radius: 3px;
		-ms-border-top-right-radius: 3px;
		-khtml-border-top-right-radius: 3px;
		border-top-right-radius: 3px;
		background: #5092BD;
		border: 1px solid #5092BD;
		border-bottom: none;
	}

	.day-badge-day {
		color: #333;
		font-size: 12px;
		font-weight: bold;
		text-shadow: 1px 1px 0px white;
		line-height: 18px;
		border: 1px solid #EEE;
		border-top: none;
		border-bottom-color: #E2E2E2;
		-moz-border-radius-bottomleft: 3px;
		-webkit-border-bottom-left-radius: 3px;
		-o-border-bottom-left-radius: 3px;
		-ms-border-bottom-left-radius: 3px;
		-khtml-border-bottom-left-radius: 3px;
		border-bottom-left-radius: 3px;
		-moz-border-radius-bottomright: 3px;
		-webkit-border-bottom-right-radius: 3px;
		-o-border-bottom-right-radius: 3px;
		-ms-border-bottom-right-radius: 3px;
		-khtml-border-bottom-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}

/*#endregion */

/*#region Common */

textarea {
    max-width: 100%;
}

a
{
    .transition(color .1s linear);
}

.require-star {
    margin-left: 5px;
    color: red;
}

.withoutMargin
{
    margin: 0;
}

.cuttext
{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
[class^="icon-"].icon-green
{
    background-image: url("images/glyphicons-halflings-green.png");
}

[class^="icon-"].icon-red
{
    background-image: url("images/glyphicons-halflings-red.png");
}

.full-size
{
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.image-height24px
{
    margin-left: 5px;
    border-radius: 3px;
    height: 24px;
    cursor: pointer;
}

div.span3.color-container
{
    margin-left: 0px;
}

.cursor-pointer
{
    cursor: pointer;
}

.overflowVisible
{
    overflow-y: visible;
}

.camouflaged-links a, a.camouflaged-links {
	color: inherit;
}

.badge-header.divided .camouflaged-links div {
    margin-top:3px;
}

/*#endregion */

/*#region Checkbox */

.check-control {
	.square(20px);
	padding: 2px;
	.icon-check {
		display: none;
	}
	&.active {
		.icon-check {
			display: inline-block;
			margin: 0;
		}		
	}
}

/*#endregion */

/*#region Forms */

.form-horizontal{
	.controls > .control-span {
		display: block;
		padding-top: 5px;
	}
}

/* Mark form-horizontal with form-horizontal-divided to add horisontal dividers to control groups */
.form-horizontal.form-horizontal-divided {
	margin-bottom: @baseLineHeight;
	
	.control-group {
		padding-top: @baseLineHeight;
		padding-bottom: @baseLineHeight;
		margin-top: 0px;
		margin-bottom: 0px;
		border-bottom: 1px solid @hrBorder;
	}
}

a.control-label{
	line-height: @inputHeight;
}

/*#endregion */

/*#region Inputs */

ul[data-role="autocomplete"] > li.bit-selected.selected-item {
    max-width: 100%;
    height: 100%;
    .box-sizing(border-box);

    .item-name {
        .text-overflow();
        max-width: 90%;
        display: inline-block;
    }
}

.input-block {
    background-color: @inputBackground;
    border: 1px solid @inputBorder;
    min-height: @inputHeight;
    margin-bottom: @baseFontSize * 0.75;

    .border-radius(@inputBorderRadius);
    .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
    .transition(~"border linear .2s, box-shadow linear .2s");
    
    &:focus {
        border-color: rgba(82,168,236,.8);
        .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)");
    }
}

.text-large {
	font-size: @fontSizeLarge;
}

.text-small {
	font-size: @fontSizeSmall;
}

.text-mini {
	font-size: @fontSizeMini;
}

.input-append.input-append-button-with-icon-fill
{
    display: block;
    position: relative;
    height: @inputHeight;

    input {
        position: absolute;
        left: 0px;
        right: 39px;
    }
    .btn {
        position: absolute;
        width: 40px;
        right: 0px;
    }
}

/*#endregion */

/*#region Ribbon */

.ribbon-container {
    .ribbon-wrapper {
        //Hide it in IE8 and bellow
        display: none;
    }
}

//Ribbon doesn't supports IE8 and bellow
:root {
	.ribbon-container {
		@cornerSize: 2px;
		@ribonSize: 50px;

		overflow: visible!important;

		.ribbon-wrapper {
			display: block;
			height: @ribonSize;
			width: @ribonSize;
			overflow: hidden;
			position: absolute;
			top: -1 * @cornerSize - 2;
			right: -1 * @cornerSize - 2;

			.ribbon {
				.box-shadow(0px 0px 3px rgba(0,0,0,0.3));
				.rotate(45deg);
				font-weight: bold;
				font-size: 10px;
				position: relative;
				top: 10px;
				left: -4px;
				padding: 0;
				text-align: center;
				//sqrt(@ribonSize * @ribonSize * 2)
				width: @ribonSize * 1.414;

				&:before, &:after {
					content: "";
					border-left: @cornerSize solid transparent;
					border-right: @cornerSize solid transparent;
					position: absolute;
					bottom: -1 * @cornerSize;
				}

				&:before {
					left: 0;
				}

				&:after {
					right: 0;
				}
			}

			.ribbon-info {
				background-color: @btnInfoBackground;
				//#gradient > .vertical(@btnInfoBackground, @btnInfoBackgroundHighlight);
				color: white;

				&:before, &:after {
					border-top: @cornerSize solid darken(@btnInfoBackground, 40%);
				}

				//Safari and chrome
				@media (-webkit-min-device-pixel-ratio:0) {
					#gradient > .vertical(@btnInfoBackground, @btnInfoBackgroundHighlight);
				}
			}
		}
	}
}



/*#endregion */

/*#region Sidebar */

.sidebar-right {
	padding: 0 15px;
	border-left: 1px solid @hrBorder;

	fieldset {
        min-width: 100%;
		border-bottom: 1px solid @hrBorder;
		margin-bottom: 12px;
		padding-bottom: 12px;

		> legend {
			.box-sizing(border-box);
            line-height: @inputHeight;
			padding: 5px;
			
			+ .control-group {
				margin-top: inherit;
			}
		}
	}

	.control-group {
		overflow: hidden;

		input{
			margin-bottom: 0;
		}
	}

	.form-horizontal {
		.controls {
			margin-left: 80px;
		}

		.control-label {
			width: 70px;
		}

		.form-actions {
			padding-left: 20px;
			background: none;
		}

		.control-group {
			margin-bottom: 12px;
		}
	}

	.sidebar-header {
		@headerLineHeight: 26px;

		border-bottom: 1px solid @hrBorder;
		.box-sizing(border-box);
		line-height: @headerLineHeight;
		margin-bottom: @baseLineHeight;
		margin-top: 5px;
		padding: 5px;
		h3, h4, h5, h6 {
			&.text-overflow {
				.text-overflow();
				line-height: @headerLineHeight;
				margin: 0;
                color: @grayDark;
			}
		}
	}
}

.wrap-text {
	word-wrap: break-word;
}

/*#endregion */

/*#region Size */

.size16x16
{
	.square(16px);
}

.size32x32
{
	.square(32px);
}

.size64x64
{
	.square(64px);
}

.size128x128
{
	.square(128px);
}

.image22x22
    {
        height: 22px;
        width: 22px;
        border-radius: 3px;
        margin-right: 5px;
    }


/*#endregion */

/*#region Toolbar */

/* Main application toolbar */

.toolbar-block, .toolbar-main
{
    @toolbarHeight: 50px;

    min-height: @toolbarHeight;
    border-bottom: 1px solid @hrBorder;
    //padding: 0px @baseFontSize @baseFontSize;
    margin-bottom: @baseFontSize * 2;
    padding: @baseFontSize;
    background-color: white;

    &.inline-toolbar{
        min-height: @baseLineHeight;
    }

    .toolbar-main-controls
    {
        padding-top: (@toolbarHeight / 2) - (@inputHeight / 2);

        .nav
        {
            margin: 0;
        }
    }

    .inline-div
    {
        display: inline-block;

        .add-on
        {
            vertical-align: baseline;
        }
    }

    h1.toolbar-main-header
    {
        .text-overflow();

        @headerHeight: @baseFontSize * 2.5;
        //background-color: @black;
        
        .muted();
        line-height: @headerHeight;
        padding-top: (@toolbarHeight / 2) - (@headerHeight / 2);
        margin: 0;
        color: @black;
    }
}

/* Toolbar which can be used within page content */

.toolbar {
    border-bottom: 1px solid @hrBorder;
    margin: 0px 0px 20px 0;
    padding: 8px 0px;

    .btn.btn-link {
        border-right: 1px solid @hrBorder;
		background: none!important;
		.box-shadow(none);
    }

	.btn.btn-link.btn-link-last{
		border: none;
	}

	h4.toolbar-header{
		line-height: @inputHeight;
		margin: 0;
	}
}

/*#endregion */

/*#region Table */

/* Additional classes for tables */

.table {
    /* Apply table-clickable to tables that supports clicking */
    &.table-clickable {
        > tbody > tr {
            cursor: pointer;
        }
    }

    > thead > tr > th {
        background-color: @tableBackgroundHeader;
    }

    &.table-resizable {
        table-layout: fixed;
    }
}

/*#endregion */

/*#region Type */
[class*="large-icon-"] {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url('../Content/glyphicons-fixed-small.png');
}

h1 { font-size: @baseFontSize * 2; line-height: @baseLineHeight * 2; }
h2 { font-size: @baseFontSize * 1.75; line-height: @baseLineHeight * 1.75; }
h3 { font-size: @baseFontSize * 1.5; line-height: @baseLineHeight * 1.5; }
h4 { font-size: @baseFontSize * 1.25; line-height: @baseLineHeight * 1.25; }
h5 { font-size: @baseFontSize; }
h6 { font-size: @baseFontSize * 0.85; }

h1 small { font-size: @baseFontSize * 1.5; } // ~24px
h2 small { font-size: @baseFontSize * 1.25; } // ~18px
h3 small { font-size: @baseFontSize; }
h4 small { font-size: @baseFontSize; }

.font-bold
{
    font-weight: bold;
}
/*#endregion */

/*#region Validation */

.validation-summary-errors {
    .alert();
	.alert-error();
	
	> ul{
		margin: 0px;

		> li{
			list-style: none;
		}
	}
}

span.field-validation-error {
	display: block;
	color: red;
	padding: 2px 0px;
	margin-top: 2px;
}

/*#endregion */

/*#region IntroJs */
body .introjs-tooltip {
    padding: 20px;

    max-width: 400px;
    width: 400px;
}

[data-step-content] {
    display: none;

    &.introjs-tooltip {
        display: block;
    }
}
/*#endregion */