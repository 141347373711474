﻿/*#region Datepicker */

.ui-datepicker-calendar .ui-state-default {
	border-color: transparent;
}

/*#endregion */

/*#region Slider */

.ui-slider .ui-slider-handle {
	z-index: 1;
}

/*#endregion */