﻿.application-items.badges
{
    .item
    {
        @itemHeight: 208px;
        @itemWidth: 229px;
        &.empty
        {
            width: @itemWidth;
            height: @itemHeight;
            background-color: @brightGrayColor;
            .box-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
        }

        .badge-widget
        {
            .badge-content
            {
                height: (@baseLineHeight + @baseLineHeight / 2) * 3;
                position: relative;
            }

            .content
            {
                overflow: hidden;
                height: 100%;
            }

            .footer-wrapper
            {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: white;

                .span6
                {
                    min-height: 0px;
                    max-height: 28px;
                }
            }

            .content-wrapper
            {
                display: table-row;
            }

            .name
            {
                font-weight: bold;
            }


            .enumeration-field
            {
                .value
                {
                    border-radius: 5px;
                    padding-left: 5px;
                    padding-right: 5px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }
            }

            .contacts-field-container, .enumeration-field-container
            {
                height: 28px;
            }

            .span12
            {
                min-height: 0px;
                max-height: 7px;
                //height: 7px;
                margin: 0;

                .slider-field
                {
                    .field-container
                    {
                        height: 5px;
                        border-width: 1px;
                        border-style: solid;
                        border-radius: 5px;
                        background-color: white;
                    }

                    .field-value
                    {
                        background-color: @sliderValueColor;
                        width: 29%;
                        height: 5px;
                    }
                }
            }
        }
    }
}
