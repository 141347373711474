﻿#discussions {
    .stream-discussion {
        margin-bottom: @baseFontSize * 1.5;

        .header {
            &.badge-header {
                margin-bottom: @baseLineHeight;
            }

            .subject {
                font-size: @baseFontSize * 0.8;
                .text-overflow();
            }
        }

        .header, .content {
            .message-body {
                margin-left: 32px + @baseFontSize * 0.75;
            }
        }

        .content {
            .messages {
                max-height: 400px;
                overflow-y: auto;

                .well {
                    background-color: @inputBackground;

                    &.well.well-small {
                        min-height:32px;
                    }
                }
            }
        }
    }

    .discussions-empty {
        text-align: center;
    }
}

.arrow-container
{
    position: absolute;
}

.arrow
{
    top: 12px;
    left: -10px;
    border-right: 10px solid #e7e7e7;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    z-index: 100;
}

.arrow-border
{
    position: absolute;
    height: 0;
    width: 0;
    top: -10px;
    left: 1px;
    border-right: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}